var spot$7 = spot$7 || {};
spot$7.DataTable = /* @__PURE__ */ function() {
  var set_event_listeners = function() {
    var tables = document.getElementsByClassName("spot-data-table--clickable");
    for (var tableCounter = 0; tableCounter < tables.length; tableCounter++) {
      var currentTable = tables[tableCounter];
      var multiRowClick = currentTable.classList.contains(
        "spot-data-table--multi-clickable"
      );
      var rows = currentTable.getElementsByTagName("tr");
      for (var rowCounter = 0; rowCounter < rows.length; rowCounter++) {
        var currentRow = currentTable.rows[rowCounter];
        var createClickHandler = function(row, multi) {
          return function() {
            var clicked = row.classList.contains(
              "spot-data-table__row--clicked"
            );
            if (multi) {
              if (clicked) {
                row.classList.remove("spot-data-table__row--clicked");
              } else {
                row.classList.add("spot-data-table__row--clicked");
              }
            } else {
              var rowsInClickedTable = row.parentNode.getElementsByTagName("tr");
              for (var clickedTableRowCounter = 0; clickedTableRowCounter < rowsInClickedTable.length; clickedTableRowCounter++) {
                rowsInClickedTable[clickedTableRowCounter].classList.remove(
                  "spot-data-table__row--clicked"
                );
              }
              if (!clicked) {
                row.classList.add("spot-data-table__row--clicked");
              }
            }
          };
        };
        currentRow.onclick = createClickHandler(currentRow, multiRowClick);
      }
    }
  };
  var initialize = function initialize2() {
    set_event_listeners();
  };
  var public_vars = {
    initialize
  };
  return public_vars;
}();
if (document.readyState !== "loading") {
  spot$7.DataTable.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$7.DataTable.initialize);
}
var spot$6 = spot$6 || {};
spot$6.LightDarkToggle = function() {
  const spotTheme = localStorage.getItem("spotTheme");
  const spotBody = document.body;
  spotTheme && spotBody.classList.add(spotTheme);
  function handleDarkModeTrigger(event) {
    var light_dark_toggle_container_id = event.target.getAttribute("data-spot-trigger-light-dark-toggle");
    var light_dark_toggle_title_id = event.target.closest(".spot-light-dark-toggle").getAttribute("id");
    if (event.target.checked) {
      enableDarkMode(light_dark_toggle_container_id);
      updateDarkModeTitle(light_dark_toggle_title_id);
    } else {
      disableDarkMode(light_dark_toggle_container_id);
      updateLightModeTitle(light_dark_toggle_title_id);
    }
  }
  function enableDarkMode(light_dark_toggle_container_id) {
    var light_dark_toggle_container = document.getElementById(light_dark_toggle_container_id);
    spotBody.classList.add("spot-dark-mode");
    localStorage.setItem("spotTheme", "spot-dark-mode");
    if (light_dark_toggle_container.classList.contains("spot-container--light-primary")) {
      light_dark_toggle_container.classList.add("spot-container--dark-primary");
      light_dark_toggle_container.classList.remove("spot-container--light-primary");
    } else {
      light_dark_toggle_container.classList.add("spot-container--dark-secondary");
      light_dark_toggle_container.classList.remove("spot-container--light-secondary");
    }
  }
  function disableDarkMode(light_dark_toggle_container_id) {
    var light_dark_toggle_container = document.getElementById(light_dark_toggle_container_id);
    spotBody.classList.remove("spot-dark-mode");
    localStorage.removeItem("spotTheme");
    if (light_dark_toggle_container.classList.contains("spot-container--dark-primary")) {
      light_dark_toggle_container.classList.remove("spot-container--dark-primary");
      light_dark_toggle_container.classList.add("spot-container--light-primary");
    } else {
      light_dark_toggle_container.classList.remove("spot-container--dark-secondary");
      light_dark_toggle_container.classList.add("spot-container--light-secondary");
    }
  }
  function updateLightModeTitle(light_dark_toggle_title_id) {
    var light_dark_toggle_title = document.getElementById(light_dark_toggle_title_id);
    if (light_dark_toggle_title.classList.contains("spot-light-dark-toggle__icon")) {
      light_dark_toggle_title.setAttribute("title", "Toggle to dark mode");
    } else {
      light_dark_toggle_title.setAttribute("title", "Dark mode off");
    }
  }
  function updateDarkModeTitle(light_dark_toggle_title_id) {
    var light_dark_toggle_title = document.getElementById(light_dark_toggle_title_id);
    if (light_dark_toggle_title.classList.contains("spot-light-dark-toggle__icon")) {
      light_dark_toggle_title.setAttribute("title", "Toggle to light mode");
    } else {
      light_dark_toggle_title.setAttribute("title", "Dark mode on");
    }
  }
  function setEventListeners() {
    var toggles = document.querySelectorAll("*[data-spot-trigger-light-dark-toggle]");
    toggles.forEach(function(toggle) {
      toggle.addEventListener("change", handleDarkModeTrigger);
    });
  }
  var initialize = function initialize2() {
    setEventListeners();
  };
  var public_vars = {
    "initialize": initialize
  };
  return public_vars;
}();
if (document.readyState !== "loading") {
  spot$6.LightDarkToggle.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$6.LightDarkToggle.initialize);
}
var spot$5 = spot$5 || {};
(function() {
  if (typeof NodeList.prototype.forEach === "function") {
    return false;
  } else {
    NodeList.prototype.forEach = Array.prototype.forEach;
    return true;
  }
})();
spot$5.Modal = /* @__PURE__ */ function() {
  function closest(element, parentClass) {
    parentClass = typeof parentClass === "undefined" ? "spot-modal__overlay" : parentClass;
    var closestParent = element;
    if (!closestParent.classList.contains(parentClass)) {
      closestParent = element.parentNode;
      while (!closestParent.classList.contains(parentClass)) {
        closestParent = closestParent.parentNode;
      }
    }
    return closestParent;
  }
  function handleModalTrigger(event) {
    var modal_overlay_id = event.target.getAttribute("data-spot-trigger-modal");
    showModal(modal_overlay_id);
  }
  function handleModalCancel(event) {
    var modal_overlay_id = closest(
      event.target,
      "spot-modal__overlay"
    ).getAttribute("id");
    hideModal(modal_overlay_id);
  }
  function handleOverlayClick(event) {
    if (event.target.classList.contains("spot-modal__overlay")) {
      if (event.target.classList.contains("spot-modal__overlay--disable")) {
        return;
      }
      var modal_overlay_id = event.target.getAttribute("id");
      hideModal(modal_overlay_id);
    }
  }
  function hideModal(modal_overlay_id) {
    var modal_overlay = document.getElementById(modal_overlay_id);
    modal_overlay.classList.remove("spot-modal__overlay--visible");
    enableBody();
  }
  function showModal(modal_overlay_id) {
    var modal_overlay = document.getElementById(modal_overlay_id);
    modal_overlay.classList.add("spot-modal__overlay--visible");
    disableBody();
  }
  function showMultistepStepNumber(step, modal) {
    var stepElements = modal.querySelectorAll(
      ".spot-modal--multistep-step-" + step
    );
    if (stepElements.length > 0) {
      var visibleStepElements = modal.querySelectorAll(
        ".spot-modal--multistep-visible"
      );
      visibleStepElements.forEach(function(element) {
        element.classList.remove("spot-modal--multistep-visible");
      });
      stepElements.forEach(function(element) {
        element.classList.add("spot-modal--multistep-visible");
      });
    }
  }
  function handleMultistepNextClick(button) {
    var index = button.target.getAttribute("data-modal-multistep-index"), modal = closest(button.target, "spot-modal"), nextStep = parseInt(index, 10) + 1;
    showMultistepStepNumber(nextStep, modal);
  }
  function handleMultistepPreviousClick(button) {
    var index = button.target.getAttribute("data-modal-multistep-index"), modal = closest(button.target, "spot-modal"), previousStep = parseInt(index, 10) - 1;
    showMultistepStepNumber(previousStep, modal);
  }
  function setEventListeners() {
    var toggles = document.querySelectorAll("*[data-spot-trigger-modal]");
    toggles.forEach(function(toggle) {
      toggle.addEventListener("click", handleModalTrigger);
    });
    var cancelLinks = document.querySelectorAll(
      ".spot-modal__header-cancel-button, .spot-modal__footer-cancel-button"
    );
    cancelLinks.forEach(function(cancel) {
      cancel.addEventListener("click", handleModalCancel);
    });
    var overlays = document.querySelectorAll(".spot-modal__overlay");
    overlays.forEach(function(overlay) {
      overlay.addEventListener("click", handleOverlayClick);
    });
    var multistepNextButtons = document.querySelectorAll(
      ".spot-modal--multistep-next"
    );
    multistepNextButtons.forEach(function(button) {
      button.addEventListener("click", handleMultistepNextClick);
    });
    var multistepPreviousButtons = document.querySelectorAll(
      ".spot-modal--multistep-previous"
    );
    multistepPreviousButtons.forEach(function(button) {
      button.addEventListener("click", handleMultistepPreviousClick);
    });
  }
  function disableBody() {
    var element = document.getElementsByTagName("body")[0];
    if (element) {
      element.classList.add("spot-modal-open");
    }
  }
  function enableBody() {
    var element = document.getElementsByTagName("body")[0];
    if (element) {
      element.classList.remove("spot-modal-open");
    }
  }
  var initialize = function initialize2() {
    setEventListeners();
  };
  var public_vars = {
    initialize
  };
  return public_vars;
}();
if (document.readyState !== "loading") {
  spot$5.Modal.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$5.Modal.initialize);
}
var spot$4 = spot$4 || {};
(function() {
  if (typeof NodeList.prototype.forEach === "function") {
    return false;
  } else {
    NodeList.prototype.forEach = Array.prototype.forEach;
    return true;
  }
})();
spot$4.Panel = /* @__PURE__ */ function() {
  function closest(element, parentClass) {
    parentClass = typeof parentClass === "undefined" ? "spot-panel" : parentClass;
    var closestParent = element;
    if (!closestParent.classList.contains(parentClass)) {
      closestParent = element.parentNode;
      while (!closestParent.classList.contains(parentClass)) {
        closestParent = closestParent.parentNode;
      }
    }
    return closestParent;
  }
  function handlePanelToggle(event) {
    var panel_overlay_id = event.target.getAttribute("data-spot-trigger-panel");
    togglePanel(panel_overlay_id);
  }
  function handlePanelClose(event) {
    var panel_overlay_id = closest(event.target, "spot-panel").getAttribute(
      "id"
    );
    hidePanel(panel_overlay_id);
  }
  function handleOverlayClick(event) {
    if (event.target.classList.contains("spot-panel")) {
      if (event.target.classList.contains("spot-panel__overlay--disable")) {
        return;
      }
      var panel_overlay_id = event.target.getAttribute("id");
      hidePanel(panel_overlay_id);
    }
  }
  function hidePanel(panel_overlay_id) {
    var panel_overlay = document.getElementById(panel_overlay_id);
    panel_overlay.classList.remove("spot-panel--visible");
    enableBody();
  }
  function togglePanel(panel_overlay_id) {
    var panel_overlay = document.getElementById(panel_overlay_id);
    panel_overlay.classList.toggle("spot-panel--visible");
  }
  function setEventListeners() {
    var toggles = document.querySelectorAll("*[data-spot-trigger-panel]");
    toggles.forEach(function(toggle) {
      toggle.addEventListener("click", handlePanelToggle);
    });
    var closeLinks = document.querySelectorAll(
      ".spot-panel__header__close-button"
    );
    closeLinks.forEach(function(close) {
      close.addEventListener("click", handlePanelClose);
    });
    var overlays = document.querySelectorAll(".spot-panel__overlay");
    overlays.forEach(function(overlay) {
      overlay.addEventListener("click", handleOverlayClick);
    });
  }
  function enableBody() {
    var element = document.getElementsByTagName("body")[0];
    if (element) {
      element.classList.remove("spot-panel--open");
    }
  }
  var initialize = function initialize2() {
    setEventListeners();
  };
  var public_vars = {
    initialize
  };
  return public_vars;
}();
if (document.readyState !== "loading") {
  spot$4.Panel.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$4.Panel.initialize);
}
var spot$3 = spot$3 || {};
spot$3.Popover = function() {
  (function addClosestPolyfill() {
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s), i, el = this;
        do {
          i = matches.length;
          while (--i >= 0 && matches.item(i) !== el) {
          }
        } while (i < 0 && (el = el.parentElement));
        return el;
      };
    }
  })();
  function Popover(element, config) {
    this.target = element;
    this.config = config ? config : {};
    this._initConfig();
    if (this.open === void 0) {
      this._init();
    }
  }
  Popover.prototype._initConfig = function() {
    if (this.config.triggers) {
      this.triggers = this.config.triggers;
    } else {
      this.triggers = this.target.id ? document.querySelectorAll(
        '[spot-popover-id="' + this.target.id + '"]'
      ) : [];
    }
    this.config.positionClasses = this.config.positionClasses ? this.config.positionClasses : [
      "spot-popover--top-center",
      "spot-popover--top-right",
      "spot-popover--center-right",
      "spot-popover--bottom-right",
      "spot-popover--bottom-center",
      "spot-popover--bottom-left",
      "spot-popover--center-left",
      "spot-popover--top-left"
    ];
    if (this.config.defaultPositionClass) {
      this.setPositionClass(this.config.defaultPositionClass);
    } else {
      this.config.defaultPositionClass = this.getCurrentPositionClass();
    }
    this.config.autoPositionTopOffset = this.config.autoPositionTopOffset ? this.config.autoPositionTopOffset : 0;
    this.config.autoPositionBottomOffset = this.config.autoPositionBottomOffset ? this.config.autoPositionBottomOffset : 0;
    this.config.autoPositionLeftOffset = this.config.autoPositionLeftOffset ? this.config.autoPositionLeftOffset : 0;
    this.config.autoPositionRightOffset = this.config.autoPositionRightOffset ? this.config.autoPositionRightOffset : 0;
  };
  Popover.prototype._init = function() {
    var that = this;
    this.open = false;
    this.clickHandler = function() {
      that.toggle();
    };
    Array.prototype.forEach.call(this.triggers, function(el) {
      el.addEventListener("click", that.clickHandler, false);
    });
    this.windowClickHandler = this.windowClick.bind(this);
  };
  Popover.prototype._init = function() {
    var that = this;
    this.open = false;
    this.clickHandler = function() {
      that.toggle();
    };
    Array.prototype.forEach.call(this.triggers, function(el) {
      el.addEventListener("click", that.clickHandler, false);
    });
    this.windowClickHandler = this.windowClick.bind(this);
  };
  Popover.prototype.toggle = function() {
    this.open = !this.open;
    this.target.classList.toggle("spot-popover--shown");
    if (this.config.autoPosition || this.target.classList.contains("spot-popover--auto-position")) {
      if (this.open) {
        this.autoPosition();
      } else {
        this.setPositionClass(this.config.defaultPositionClass);
      }
    }
      if (this.open) {
      window.addEventListener("click", this.windowClickHandler, true);
      if (this.config.onOpen) {
        this.config.onOpen.apply(this);
      }
    } else {
      window.removeEventListener("click", this.windowClickHandler, true);
      if (this.config.onClose) {
        this.config.onClose.apply(this);
      }
    }
  };
  Popover.prototype.windowClick = function(event) {
    if (this.isOpen() && !this.isElementInsidePopoverTriggers(event.target) && !this.isElementInsidePopover(event.target)) {
      this.toggle();
    }
  };
  Popover.prototype.autoPosition = function() {
    var that = this;
    this.config.positionClasses.forEach(function(positionClass) {
      if (that.isPopoverInViewport()) {
        return;
      } else {
        that.setPositionClass(positionClass);
      }
    });
    if (!this.isPopoverInViewport()) {
      this.setPositionClass(this.config.defaultPositionClass);
    }
  };
  Popover.prototype.setPositionClass = function(newPostionClass) {
    var that = this;
    this.config.positionClasses.forEach(function(positionClass) {
      that.target.classList.remove(positionClass);
    });
    this.target.classList.add(newPostionClass);
  };
  Popover.prototype.getCurrentPositionClass = function() {
    var that = this;
    var currentPositionClass = null;
    this.config.positionClasses.forEach(function(positionClass) {
      if (that.target.classList.contains(positionClass)) {
        currentPositionClass = positionClass;
      }
    });
    return currentPositionClass;
  };
  Popover.prototype.isPopoverInViewport = function() {
    if (this.open) {
      var popoverGeometry = this.target.getBoundingClientRect();
      var popoverTop = popoverGeometry.top - this.config.autoPositionTopOffset;
      var popoverBottom = popoverGeometry.bottom + this.config.autoPositionBottomOffset;
      var popoverLeft = popoverGeometry.left - this.config.autoPositionLeftOffset;
      var popoverRight = popoverGeometry.right + this.config.autoPositionRightOffset;
      if (popoverTop < 0) {
        return false;
      }
      if (popoverRight > window.innerWidth || popoverRight > document.documentElement.clientWidth) {
        return false;
      }
      if (popoverBottom > window.innerHeight || popoverBottom > document.documentElement.clientHeight) {
        return false;
      }
      if (popoverLeft < 0) {
        return false;
      }
    }
    return true;
  };
  Popover.prototype.isElementInsidePopover = function(element) {
    return this.target.contains(element);
  };
  Popover.prototype.isElementInsidePopoverTriggers = function(element) {
    return Array.prototype.some.call(this.triggers, function(trigger) {
      return trigger.contains(element);
    });
  };
  Popover.prototype.isOpen = function() {
    return this.open;
  };
  Popover.prototype.teardown = function() {
    var that = this;
    this.target.classList.remove("spot-popover--shown");
    Array.prototype.forEach.call(this.triggers, function(el) {
      el.removeEventListener("click", that.clickHandler, false);
    });
    delete this.clickHandler;
    delete this.triggers;
    delete this.open;
  };
  return {
    create,
    destroy,
    initialize
  };
  function create(targetEl, config) {
    if (!targetEl.spotPopover) {
      targetEl.spotPopover = new Popover(targetEl, config);
    }
  }
  function destroy(targetEl) {
    if (targetEl.spotPopover) {
      targetEl.spotPopover.teardown();
    }
  }
  function initialize() {
    var popovers = document.getElementsByClassName("spot-popover");
    for (var i = 0; i < popovers.length; i++) {
      create(popovers[i]);
    }
  }
}();
if (document.readyState !== "loading") {
  spot$3.Popover.initialize();
} else {
    document.addEventListener("DOMContentLoaded", spot$3.Popover.initialize);
}
var spot$2 = spot$2 || {};
spot$2.SplitButton = /* @__PURE__ */ function() {
  function handleDropdownDismiss(obj) {
    if (obj)
      obj.style.display = "none";
  }
  function handleSplitButtonTrigger(obj) {
    var parent = obj.parentNode;
    var dropList = parent.querySelector(".spot-dropdown");
    if (dropList) {
      if (dropList.style.display === "none" || dropList.style.display === "") {
        dropList.style.display = "block";
      } else {
        dropList.style.display = "none";
      }
      var classList = document.getElementsByClassName("spot-dropdown");
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] !== dropList) {
          classList[i].style.display = "none";
        }
      }
    }
  }
  function setEventListeners() {
    var forEach = Array.prototype.forEach;
    var buttons = document.getElementsByClassName(
      "spot-button--dropdown-toggle"
    );
    forEach.call(buttons, function(buttons2) {
      buttons2.onclick = function() {
        handleSplitButtonTrigger(this);
      };
    });
    forEach = Array.prototype.forEach;
    var links = document.getElementsByClassName("spot-dropdown");
    forEach.call(links, function(link) {
      link.onclick = function() {
        handleDropdownDismiss(this);
      };
    });
  }
  var initialize = function initialize2() {
    setEventListeners();
  };
  var public_vars = {
    initialize
  };
  return public_vars;
}();
if (document.readyState !== "loading") {
  spot$2.SplitButton.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$2.SplitButton.initialize);
}
var spot$1 = spot$1 || {};
spot$1.Message = function() {
  function Message(element, config) {
    this.defaults = {
      display: "inline",
      dismissible: false,
      timer: 6e3,
      priority: "standard",
      alertLevel: "default",
      icon: false,
      iconPath: "/dist/icons/spot_icons.svg",
      progressBar: false,
      animationIn: false,
      animationOut: "fadeOut",
      position: "top-right",
      newestOnTop: false
    };
    this.target = element;
    this.container = document.body;
    this.config = config ? Object.assign({}, this.defaults, config) : this.defaults;
    if (this.config.display !== "inline") {
      this._initFromConfig();
    }
    this._init();
  }
  Message.prototype._remove = function() {
    var self = this;
    if (this.config.animationOut) {
      this.target.style.animationName = this.config.animationOut;
    }
    setTimeout(function() {
      self.target.remove();
      if (self.container !== document.body && self.container.childElementCount === 0) {
        self.container.remove();
      }
    }, 800);
  };
  Message.prototype.teardown = function() {
    if (this.config.onDismiss) {
      this.config.onDismiss.apply(this);
    }
    this._remove();
  };
  Message.prototype._initFromConfig = function() {
    this._initParentContainer();
    this._initTargetFromConfig();
    this._initMessageContent();
    this._initIconTab();
    this._initDismissible();
  };
  Message.prototype._init = function() {
    this._initTarget();
    this._initDismissibleEventListener();
    this._initProgressBar();
  };
  Message.prototype._initParentContainer = function() {
    if (!this._allowMultiple()) {
      return;
    }
    var toastContainerEl = document.querySelector(
      ".toast-container--" + this.config.position
    );
    if (toastContainerEl) {
      this.container = toastContainerEl;
      return;
    }
    this.container = document.createElement("div");
    this.container.classList.add("toast-container--" + this.config.position);
    document.body.append(this.container);
  };
  Message.prototype._initTargetFromConfig = function() {
    this.target.classList.add("spot-message");
    if (this.config.alertLevel !== "") {
      this.target.classList.add(
        "priority-" + this.config.priority + "--" + this.config.alertLevel
      );
    } else {
      this.target.classList.add("priority-" + this.config.priority);
    }
    this.target.classList.add("spot-" + this.config.display);
    this.target.classList.add(this.config.position);
    if (this.config.animationIn) {
      this.target.style.animationName = this.config.animationIn;
    }
    if (this._allowMultiple()) {
      this._enableStacking();
    }
    this._insertTarget();
  };
  Message.prototype._insertTarget = function() {
    if (this.config.newestOnTop) {
      this.container.prepend(this.target);
    } else {
      this.container.appendChild(this.target);
    }
  };
  Message.prototype._allowMultiple = function() {
    return !this.config.position.endsWith("center") && !this.config.position.endsWith("full");
  };
  Message.prototype._enableStacking = function() {
    this.target.style.position = "relative";
  };
  Message.prototype._initTarget = function() {
    this.target.classList.add("animated");
  };
  Message.prototype._initMessageContent = function() {
    var messageContentEl = document.createElement("div");
    messageContentEl.classList.add("spot-message--content");
    this.target.appendChild(messageContentEl);
    if (this.config.title) {
      var messageHeaderEl = document.createElement("div");
      messageHeaderEl.classList.add("spot-message--header");
      messageHeaderEl.innerHTML = this.config.title;
      messageContentEl.appendChild(messageHeaderEl);
    }
    if (this.config.html) {
      var messageBodyEl = document.createElement("div");
      messageBodyEl.classList.add("spot-message--body");
      messageBodyEl.innerHTML = this.config.html;
      messageContentEl.appendChild(messageBodyEl);
    }
  };
  Message.prototype._initIconTab = function() {
    if (!this.config.icon) {
      return;
    }
    var iconTabContentEl = document.createElement("div");
    iconTabContentEl.classList.add("icon-tab");
    iconTabContentEl.appendChild(this._createSvg(this.config.icon));
    this.target.appendChild(iconTabContentEl);
  };
  Message.prototype._initDismissible = function() {
    if (!this.config.dismissible) {
      return;
    }
    this.target.classList.add("dismissible");
    var dismissContentEl = document.createElement("div");
    dismissContentEl.classList.add("dismiss");
    dismissContentEl.appendChild(this._createSvg("cancel"));
    this.target.appendChild(dismissContentEl);
  };
  Message.prototype._createSvg = function(icon) {
    var svgEl = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svgEl.classList.add("spot-icon");
    svgEl.setAttributeNS(
      "http://www.w3.org/1999/xlink",
      "aria-labelledby",
      "title"
    );
    var svgTitleEl = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "title"
    );
    svgTitleEl.innerHTML = this.config.icon;
    svgEl.appendChild(svgTitleEl);
    var svgUseEl = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "use"
    );
    svgUseEl.setAttributeNS(
      "http://www.w3.org/1999/xlink",
      "xlink:href",
      this.config.iconPath + "#" + icon
    );
    svgEl.appendChild(svgUseEl);
    return svgEl;
  };
  Message.prototype._initDismissibleEventListener = function() {
    var self = this;
    if (!this.target.classList.contains("dismissible")) {
      return;
    }
    var el = this.target.querySelector(".dismiss");
    if (el) {
      el.addEventListener(
        "click",
        function() {
          self.teardown();
        },
        { once: true }
      );
    } else {
      console.error(
        "Invalid configuration: Dismissible messages require a dismiss element!"
      );
    }
  };
  Message.prototype._initProgressBar = function() {
    if (!this.config.progressBar) {
      return;
    }
    var progressBarEl = document.createElement("span");
    progressBarEl.classList.add("progress");
    this.target.appendChild(progressBarEl);
    this._transitionProgressBar(progressBarEl);
    this._autoDismissProgressBar();
  };
  Message.prototype._transitionProgressBar = function(progressBarEl) {
    progressBarEl.style.width = "100%";
    progressBarEl.style.transition = "width " + this.config.timer + "ms linear";
    setTimeout(function() {
      progressBarEl.style.width = "0%";
    }, 100);
  };
  Message.prototype._autoDismissProgressBar = function() {
    var self = this;
    setTimeout(function() {
      self.teardown();
    }, this.config.timer);
  };
  return {
    toast,
    dialog,
    create,
    destroy,
    initialize
  };
  function toast(config) {
    var el = document.createElement("div");
    create(
      el,
      Object.assign({}, null, config, {
        display: "toast"
      })
    );
  }
  function dialog(config) {
    var el = document.createElement("div");
    create(
      el,
      Object.assign({}, config, {
        display: "dialog",
        progressBar: false,
        position: "center-center",
        newestOnTop: false
      })
    );
  }
  function create(targetEl, config) {
    if (!targetEl.spotMessage) {
      targetEl.spotMessage = new Message(targetEl, config);
    }
  }
  function destroy(targetEl) {
    if (targetEl.spotMessage) {
      targetEl.spotMessage.teardown();
    }
  }
  function initialize() {
    var messages = document.querySelectorAll(".spot-message");
    for (var i = 0; i < messages.length; i++) {
      create(messages[i]);
    }
  }
}();
if (document.readyState !== "loading") {
  spot$1.Message.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot$1.Message.initialize);
}
var spot = spot || {};
spot.Tabs = /* @__PURE__ */ function() {
  function tabLinkClicked(event) {
    event.preventDefault();
    var link = closest(event.target, "spot-tabs__link"), activeTabId = link.getAttribute("href").replace("#", "");
    setActiveTab(link);
    setActiveTabPane(activeTabId);
  }
  function closest(element, parentClass) {
    parentClass = typeof parentClass === "undefined" ? "spot-tabs" : parentClass;
    var closestParent = element;
    if (!closestParent.classList.contains(parentClass)) {
      closestParent = element.parentNode;
      while (!closestParent.classList.contains(parentClass)) {
        closestParent = closestParent.parentNode;
      }
    }
    return closestParent;
  }
  function setActiveTab(activeTab) {
    var tabset = closest(activeTab, "spot-tabs"), tabs = tabset.querySelectorAll(".spot-tabs__link"), activeTabHref = activeTab.getAttribute("href");
    for (var i = 0; i < tabs.length; i++) {
      var tab = tabs[i];
      if (tab.getAttribute("href") === activeTabHref) {
        tab.classList.add("spot-tabs__link--active");
        if (tabset.classList.contains("spot-tabs--scrollable")) {
          var leftEdge = tab.offsetLeft, scrollButtonWidth = tabset.querySelector(
            ".spot-tabs__scroll-button"
          ).offsetWidth, scrollPane = tabset.querySelector(".spot-tabs__list"), scrollPosition = leftEdge - scrollButtonWidth;
          animateScrollToPosition(scrollPane, scrollPosition);
        }
      } else {
        tab.classList.remove("spot-tabs__link--active");
      }
    }
  }
  function setActiveTabPane(activeTabId) {
    var tabset = closest(document.getElementById(activeTabId), "spot-tabs"), tabContentPanes = tabset.querySelectorAll(".spot-tabs__content");
    for (var i = 0; i < tabContentPanes.length; i++) {
      var pane = tabContentPanes[i], paneId = pane.getAttribute("id");
      if (paneId !== activeTabId) {
        pane.classList.add("spot-tabs__content--hidden");
      } else {
        pane.classList.remove("spot-tabs__content--hidden");
      }
    }
  }
  function tabLabelsWiderThanComponent(tabs) {
    var labelsWidth = 0, tabLabelClass = "spot-tabs__tab", labels = tabs.querySelectorAll("." + tabLabelClass), componentWidth = tabs.offsetWidth, marginOfError = 20, storedLabelsWidth = tabs.getAttribute("data-spot-tab-labels-width");
    if (storedLabelsWidth === null) {
      for (var i = 0; i < labels.length; i++) {
        var label = labels[i];
        labelsWidth += label.offsetWidth + parseInt(getComputedStyle(label)["margin-left"], 10) + parseInt(getComputedStyle(label)["margin-right"], 10);
      }
      labelsWidth += marginOfError;
      tabs.setAttribute("data-spot-tab-labels-width", labelsWidth);
    } else {
      labelsWidth = storedLabelsWidth;
    }
    if (labelsWidth > componentWidth) {
      return true;
    } else {
      return false;
    }
  }
  function getAllTabsets() {
    return document.querySelectorAll(".spot-tabs");
  }
  function pageTabs(e) {
    var scrollButton = closest(e.target, "spot-tabs__scroll-button"), direction = scrollButton.classList.contains(
      "spot-tabs__scroll-button--previous"
    ) ? "left" : "right", tabset = closest(scrollButton, "spot-tabs"), scrollPane = tabset.querySelector(".spot-tabs__list"), currentScrollPosition = scrollPane.scrollLeft, distanceToScroll = scrollPane.clientWidth, maxScrollPosition = scrollPane.scrollWidth - scrollPane.clientWidth, newScrollPosition = currentScrollPosition;
    if (direction === "left") {
      newScrollPosition = currentScrollPosition - distanceToScroll;
      newScrollPosition = newScrollPosition <= 0 ? 0 : newScrollPosition;
    } else {
      newScrollPosition = currentScrollPosition + distanceToScroll;
      newScrollPosition = newScrollPosition >= maxScrollPosition ? maxScrollPosition : newScrollPosition;
    }
    animateScrollToPosition(scrollPane, newScrollPosition);
  }
  function animateScrollToPosition(scrollPane, endPosition) {
    var startPosition = scrollPane.scrollLeft, newPosition = startPosition, animationDuration = 200, tickDuration = 10, difference = Math.abs(startPosition - endPosition), distancePerTick = difference / (animationDuration / tickDuration), scrollAnimationInterval;
    scrollAnimationInterval = setInterval(function() {
      if (startPosition < endPosition) {
        newPosition += distancePerTick;
        scrollPane.scrollLeft = newPosition;
        if (newPosition >= endPosition) {
          clearInterval(scrollAnimationInterval);
        }
      } else {
        newPosition -= distancePerTick;
        scrollPane.scrollLeft = newPosition;
        if (newPosition <= endPosition) {
          clearInterval(scrollAnimationInterval);
        }
      }
    }, tickDuration);
  }
  function refreshScrollButtons(scrollPane) {
    var scrollPosition = scrollPane.scrollLeft, maxScrollLeft = scrollPane.scrollWidth - scrollPane.clientWidth, tabset = closest(scrollPane, "spot-tabs");
    if (scrollPosition <= 0) {
      tabset.classList.add("spot-tabs--previous-scroll-hidden");
    } else {
      tabset.classList.remove("spot-tabs--previous-scroll-hidden");
    }
    if (scrollPosition >= maxScrollLeft) {
      tabset.classList.add("spot-tabs--next-scroll-hidden");
    } else {
      tabset.classList.remove("spot-tabs--next-scroll-hidden");
    }
  }
  function scrollPaneScrolled(e) {
    var scrollPane = e.target;
    refreshScrollButtons(scrollPane);
  }
  function refreshTabStates() {
    var tabsets = getAllTabsets();
    for (var i = 0; i < tabsets.length; i++) {
      var tabset = tabsets[i];
      if (tabLabelsWiderThanComponent(tabset)) {
        tabset.classList.add("spot-tabs--responsive");
      } else {
        tabset.classList.remove("spot-tabs--responsive");
      }
      if (tabset.classList.contains("spot-tabs--scrollable")) {
        refreshScrollButtons(tabset.querySelector(".spot-tabs__list"));
      }
    }
  }
  function scrollActiveTabIntoView(tabset, activeTabId) {
    var activeTab = tabset.querySelector(
      '.spot-tabs__link[href="#' + activeTabId + '"]'
    ), scrollPane = tabset.querySelector(".spot-tabs__list"), prevButton = tabset.querySelector(".spot-tabs__scroll-button--previous"), prevButtonWidth = prevButton.offsetWidth, activeTabLeftOffset = activeTab.offsetLeft, activeTabPosition = activeTabLeftOffset - prevButtonWidth;
    animateScrollToPosition(scrollPane, activeTabPosition);
  }
  function setEventListeners() {
    var tabLinks = document.querySelectorAll(".spot-tabs__link"), scrollingTabsets = document.querySelectorAll(".spot-tabs--scrollable"), i;
    for (i = 0; i < tabLinks.length; i++) {
      var tabLink = tabLinks[i];
      tabLink.addEventListener("click", tabLinkClicked);
    }
    for (i = 0; i < scrollingTabsets.length; i++) {
      var tabset = scrollingTabsets[i], scrollPane = tabset.querySelector(".spot-tabs__list"), previousButton = tabset.querySelector(
        ".spot-tabs__scroll-button--previous"
      ), nextButton = tabset.querySelector(".spot-tabs__scroll-button--next");
      scrollPane.addEventListener("scroll", scrollPaneScrolled);
      nextButton.addEventListener("click", pageTabs);
      previousButton.addEventListener("click", pageTabs);
    }
    window.addEventListener("resize", refreshTabStates);
  }
  function setInitialTabStates() {
    var tabsets = getAllTabsets();
    for (var i = 0; i < tabsets.length; i++) {
      var tabset = tabsets[i], activeTab = tabset.querySelector(".spot-tabs__link--active"), activeTabId = activeTab.getAttribute("href").replace("#", "");
      setActiveTabPane(activeTabId);
      if (tabset.classList.contains("spot-tabs--scrollable")) {
        scrollActiveTabIntoView(tabset, activeTabId);
      }
    }
  }
  var initialize = function initialize2() {
    setInitialTabStates();
    setEventListeners();
    refreshTabStates();
  };
  return {
    initialize
  };
}();
if (document.readyState !== "loading") {
  spot.Tabs.initialize();
} else {
  document.addEventListener("DOMContentLoaded", spot.Tabs.initialize);
}
